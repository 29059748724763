<template>
    <w-entry-preview
        mini
        :icon="icon"
        :overline="item.school ? item.school.title : null"
        :title="item.title"
        :note="item.description"
        :to="to"
    />
</template>

<script>
export default {
    name: 'DepartmentPreview',
    props: {
        item: {
            type: Object,
            required: true
        },
        to: {
            type: [ String, Object ],
            default: undefined
        },
        icon: {
            type: String,
            default: 'DEPARTMENT'
        }
    },
    computed: {
        vCardProps() {
            const props = {}

            if (this.to) {
                props.to = this.to
                props.hover = true
                props.outlined = true
            }

            return props
        }
    }
}
</script>

<style lang="scss">

</style>
