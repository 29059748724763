<template>
    <content-wrapper
        v-if="departments"
        :title="title"
    >
        <v-row no-gutters>
            <v-col
                cols="12"
                v-for="item in departmentsComputed"
                :key="item.uuid"
                class="py-1"
            >
                <department-preview
                    :item="item"
                    :to="{name: 'departments.show', params: {uuid: item.uuid}}"
                />
            </v-col>
        </v-row>
    </content-wrapper>
</template>

<script>
import ContentWrapper from '@/components/ContentWrapper'
import DepartmentPreview from '@/apps/school/components/Department/DepartmentPreview'
import { getDepartmentsCollection } from '@apps/school/api/departments'
import hasDepartmentsPropertyMixin from '@apps/school/mixins/hasDepartmentsPropertyMixin'

export default {
    name: 'Departments',
    mixins: [hasDepartmentsPropertyMixin],
    components: { DepartmentPreview, ContentWrapper },
    computed: {
        title() {
            return this.$trans('Departments')
        },
        breadcrumbs() {
            return [
                {
                    text: this.$trans('Departments')
                }
            ]
        },
        departmentsComputed() {
            if(!this.$auth.isAssistant()) {
                return this.departments
            }

            return this.items.filter(o => {
                return this.$auth.hasPermission('department', o.uuid)
            })
        }
    },
    data() {
        return {
            items: []
        }
    },
    mounted() {
        if(this.$auth.isAssistant()) {
            this.fetchDepartments()
        }
    },
    methods: {
        async fetchDepartments() {
            this.$lprogress.begin()

            try {
                const response = await getDepartmentsCollection({
                    school: 'all',
                    with: 'school'
                })
                this.items = response.data.data
            } finally {
                this.$lprogress.end()
            }
        }
    }
}
</script>

<style lang="scss">

</style>
